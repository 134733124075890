export const projects = [
  {
    id: 11,
    title: "Batch Scheduler",
    type: "Web",
    description: "A tool for visualizing complex batch processes",
    details:
      "This tool is loosly based on ISA 88 Batch control terminalogy. Equipment is added to the model on the equipment screen. Operations can be added to each peice of equipment describing a procedural step. Required resources can be added to each operation. The combined operations create a gantt chart for each equipment which layout the procedure. Multiple batches can be combined into a campaign and the detail's on the campaign's resource requirements are shown on the resource screen. A summary screen is provided to visualize the bottleneck and equipment occupancy details.",
    contrubutors: "I developed this tool on my own.",
    technologies: ["HTML", "JavaScript", "React", "NextJS", "Netlify"],
    features: [
      "Equipment occupancy chart",
      "Operation gantt charts",
      "Resource estimations",
      "Bottleneck calculations",
      "Multiple batches",
      "Save to desktop",
      "Load files",
    ],
    gitHubUrl: "https://github.com/mama4294/process-app",
    liveUrl: "https://gorgeous-gelato-72b322.netlify.app",
    pictures: [
      "/processVis-equipment.png",
      "/processVis-editResource.png",
      "/processVis-resources.png",
      "/processVis-editResource.png",
      "/processVis-summary.png",
      "/processVis-open.png",
      "/processVis-confirmation.png",
    ],
  },
  {
    id: 3,
    title: "Engineering Toolbox",
    type: "Web",
    contrubutors: "I developed and hosted this site on my own.",
    description: "A toolbox for process engineering calculations",
    details:
      "I converted my commonly used engineering calculation from excel into an online toolbox. It contains a number of tools for calculating fluid dynamics, heat transfer, and other various engineering problems. I decided to make this website soley with vanilla HTML, CSS, and JavaScript to challenge my understanding of the basics. In the end, I used a small amount of JQuery to make the navbar appear on every page.",
    features: [
      "Ten commonly used process engineering calculators",
      "Dynamic unts system",
      "Input validation",
      "Error handling",
      "Recursive functions",
    ],
    technologies: ["HTML", "Vanilla JavaScript", "CSS", "JQuery"],
    gitHubUrl: "https://github.com/mama4294/Engineering-Toolbox",
    liveUrl: "https://lambent-chebakia-e828e4.netlify.app/",
    pictures: [
      "/toolbox-1.png",
      "/toolbox-5.png",
      "/toolbox-6.png",
      "/toolbox-2.png",
      "/toolbox-3.png",
      "/toolbox-4.png",
    ],
  },
  {
    id: 2,
    title: "Wordle",
    type: "Web",
    description: "A daily word game",
    contrubutors: "I developed this game on my own.",
    details:
      "I created a clone of Wordle, a popular online word game the New York Times, as a way to practice my JavaScript skills. I modified the game to allow for multiple games per day",
    technologies: ["HTML", "JavaScript", "React", "TailwindCSS", "Netlify"],
    features: [
      "Randomly generated words",
      "On-screen keyboard with dynamic colors",
      "Input validation",
      "Multiple games per day",
    ],
    gitHubUrl: "https://github.com/mama4294/wordle",
    liveUrl: "https://spectacular-dasik-2a887b.netlify.app/",
    pictures: ["/wordle-macbook.png", "/wordle-1.png", "/wordle-3.png"],
  },
  // {
  //   id: 1,
  //   title: "E-commerce",
  //   type: "Web",
  //   description: "An E-commerce website for a clothing store",
  //   details:
  //     "A website for a clothing store. It has a login system, a shopping cart, and a payment system. It uses React as the frontend framework and uses Firebase as the backend. It is deployed on Netlify and uses Netlify cloud funtions for Stripe payments. State managment is handled by Redux.",
  //   contrubutors: "I created this e-commerce website as a part of a bootcamp",
  //   technologies: [
  //     "HTML",
  //     "JavaScript",
  //     "React",
  //     "React Router",
  //     "Redux",
  //     "Styled Components",
  //     "Firebase",
  //     "Firestore",
  //     "Firebase Auth",
  //     "Netlify",
  //     "Netlify Functions",
  //     "Stripe",
  //   ],
  //   features: [
  //     "Email and Google authentication",
  //     "Shopping cart",
  //     "Pull in products from Firebase",
  //     "Stripe payments",
  //   ],
  //   gitHubUrl: "https://github.com/mama4294/ecommerce",
  //   liveUrl: "https://sprightly-lokum-7b8361.netlify.app",
  //   pictures: [
  //     "/e-commerce-main.png",
  //     "/e-commerce-2.png",
  //     "/e-commerce-3.png",
  //     "/e-commerce-4.png",
  //   ],
  // },
  {
    id: 4,
    title: "Automated Fermenter Cleaning",
    type: "Industrial Automation",
    description:
      "A program to clean bioreactors at a GMP fermentation facility",
    details:
      "I automated the cleaning of 12 bioreactors in a GMP fermentation facility. I coded it using a combination of structured text, function-block, and sequential function charts programming on an ABB AC-700F DCS. After selection of the bioreactor, the program automatically runs through pre-rinse, caustic-detergent, acid-descaling, and post-rinse cycles. Individual valve seats are automatically pulsed to clean all aspects of the bioreactor and an airpush inbetween cycles reduces waste. This program replaced manual cleaning cycles which increased cleaning efficiency and allowed the control room operator to focus on more inportant tasks.",
    contrubutors:
      "I wrote this program and commissioned it with operators on the factory floor.",
    technologies: [
      "ABB AC-700F DCS",
      "ABB Freelance Engineering",
      "Function-block",
      "Structured text",
      "Sequential function charts",
      "PID control loops",
    ],
    features: [
      "Automatic cleaning of 12 bioreactors",
      "Pre-rinse, caustic-detergent, acid-descaling, and post-rinse cycles",
      "Operator input validation",
      "Error handling",
      "Alarming",
      "Estop",
    ],
    pictures: ["/cip-1.png", "/cip-2.png"],
  },
  {
    id: 5,
    title: "Centrifuge Integration",
    type: "Industrial Automation",
    description: "Integration of a production centrifuge with a plant DCS",
    details:
      "A disk stack centrifuge continously separates a feed slurry into separate liquid and solid stream. This $400k centrifuge can be operated from the factory floor but I integrated it with the factory's local control system so it could be run remotely from the factory control room.",
    contrubutors:
      "I integrated all tags into the local control system and designed the human-machine interface (HMI) to control the centrifuge. I commissioned it with engineers and operators on the factory floor.",
    technologies: [
      "ABB AC-700F DCS",
      "ABB Freelance Engineering",
      "Function-block",
      "PID control loops",
      "High performance HMI design",
      "Modbus communication",
    ],
    features: [
      "Mode selection: standby, process, CIP, and shutdown",
      "Parameter selection: feed rate, discharge time, and backpressure",
      "Discharge options: small or large",
      "Flowpath options",
      "Operator input validation",
      "Turbidity monitoring",
      "Filter differential pressure monitoring",
      "Process variable trending",
      "Error handling",
      "Alarming",
      "Estop",
    ],
    pictures: [
      "/centrifuge-1.png",
      "/centrifuge-2.png",
      "/centrifuge-3.png",
      "/centrifuge-4.png",
    ],
  },
  {
    id: 6,
    title: "Continuous Sterilizer",
    type: "Industrial Automation",
    description: "A program for resterilizing a UHT sterilizer",
    details:
      "A UHT sterilizer is a peice of industrial equipment which continuously heats a stream of slurry to an ultra high temperature to kill all microorganisms. The sterile stream is then cooled down using heat exchangers to the appropriate temperature. The fermention factory required a high level of reliability because any failures could cause thousands of dollars in contaminations. I worked with a team of control engineers to automate the machine. I later added features like resterilization without shutdown, all-auto, and operator UX improvements",
    contrubutors:
      "I built this program with a team of control engineers during plant startup. Over several years, I added features to the program on my own to improve its performance.",
    technologies: [
      "ABB AC-700F DCS",
      "ABB Freelance Engineering",
      "PLC Programming",
      "Function-block programming",
      "Sequential function chart programming",
      "PID control loops",
      "High performance HMI design",
      "4-20mA communication",
    ],
    pictures: ["/uht-1.png", "/uht-2.png", "/uht-3.png"],
    features: [
      "Heating, steriliziling, cold-side cooling, and filling stages",
      "Automatic divert incase of failure",
      "Resterilization",
      "Discharge options: small or large",
      "Temperature and pressure monitoring",
      "Gentle ramp down to stop",
      "Process variable trending",
      "Error handling",
      "Alarming",
      "Estop",
    ],
  },
  {
    id: 7,
    title: "Behavioral Based Safety",
    contrubutors: "I developed and deployed this app on my own.",
    details:
      "Behavioral Based Safety (BBS) is a program to influence factory worker actions towards safer outcome through observation. I created an app through Microsoft's PowerApps to log observations and potential safety risks. I also created a PowerBi dashboard to visualize the data and provide a report to the factory workers. Observations from this app are used in part to determine operator bonuses",
    type: "Mobile",
    description: "An app for recording and analyzing safety data",
    technologies: ["Microsoft PowerApps", "SharePoint", "PowerBi"],
    features: [
      "Microsoft Authentication",
      "Observation logging",
      "Dynamic inputs based on risk selection",
      "Profile pictures",
      "Friendly dates",
      "History",
      "Search",
      "Reload",
      "Sort",
    ],
    pictures: [
      "/bbs-1.png",
      "/bbs-4.png",
      "/bbs-3.png",
      "/bbs-2.png",
      "/bbs-5.png",
    ],
  },
  {
    id: 8,
    title: "Maintenance Checks",
    type: "Mobile",
    details:
      "The maintenance team at a food and beverage facility checked the factory for problems every morning. I developed this app to give the maintenenace team a simple way to log and track issues in the factory.",
    contrubutors: "I developed and deployed this app on my own.",
    description: "An app for recording daily maintenance checks",
    technologies: ["Microsoft PowerApps", "SharePoint", "PowerBi"],
    features: [
      "Microsoft Authentication",
      "Observation logging",
      "Advice on potential issues",
      "Profile pictures",
      "Friendly dates",
      "History",
      "Search",
      "Reload",
      "Sort",
    ],
    pictures: [
      "/maint-check-1.png",
      "/maint-check-2.png",
      "/maint-check-3.png",
      "/maint-check-4.png",
    ],
  },
  {
    id: 9,
    title: "Factory Commissioning",
    type: "Mobile",
    description: "A factory commissioning and startup tracking app",
    details:
      "When a new factory is under construction, each peice of equiment needs to be qualified to ensure it was installed correctly, operates correctly, and performs the correct funtion. I developed this app to help track the commisioning progress. I also created a PowerBi dashboard to visualize the data and provide a report to the factory's management team.",
    contrubutors: "I developed and deployed this app on my own.",
    technologies: ["Microsoft PowerApps", "SharePoint", "PowerBi"],
    features: [
      "Microsoft Authentication",
      "Sort by equipment, P&ID, or system",
      "Interlock tracking",
      "Punchlist",
    ],
    pictures: [
      "/commissioning-2.png",
      "/commissioning-1.png",
      "/commissioning-3.png",
      "/commissioning-4.png",
      "/commissioning-5.png",
    ],
  },
  {
    id: 10,
    title: "Inventory Management",
    type: "Mobile",
    details:
      "I worked for a factory which required a system for tracking spare parts. Sometimes spare parts would be out of stock because there was no system to track the inventory. I developed this app to help the factory track and maintain inventory. It included location tracking, inventory management, and equipment failure history",
    description: "A management app for a factory's inventory",
    technologies: ["Microsoft PowerApps", "SharePoint", "PowerBi"],
    contrubutors: "I developed and deployed this app on my own.",
    features: [
      "Add parts to inventory",
      "Remove parts from inventory",
      "Create new part",
      "Auto part number generation",
      "Sort by number, equipment, location, make, vendor, or category",
      "Critical parts",
      "Low inventory warnings",
      "Equipment history",
      "Supply chain PO requests",
    ],
    pictures: [
      "/inventory-2.png",
      "/inventory-1.png",
      "/inventory-3.png",
      "/inventory-4.png",
      "/inventory-5.png",
      "/inventory-6.png",
      "/inventory-7.png",
      "/inventory-8.png",
      "/inventory-9.png",
      "/inventory-10.png",
      "/inventory-11.png",
    ],
  },
];
